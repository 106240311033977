@import url('https://fonts.googleapis.com/css2?family=Krona+One');
@import url('https://fonts.googleapis.com/css2?family=Barlow');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Barlow', sans-serif;
}

#root {
  max-height: 100vh;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
}

.inventory-table .ant-table-cell {
    vertical-align: top;
}

.deleted-row {
    background-image: linear-gradient(
            135deg,
            rgba(255, 192, 203, 0.5) 25%, /* Light pink with 50% transparency */
            transparent 25%,
            transparent 50%,
            rgba(255, 192, 203, 0.5) 50%,
            rgba(255, 192, 203, 0.5) 75%,
            transparent 75%,
            transparent
    );
    background-size: 20px 20px; /* Adjust the size of the diagonals */
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
